/* eslint-disable */
import React, { Suspense,useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';

const App = React.lazy(() => import('./App'));

const Main = () => {
  useEffect(() => {
    // Google Tag Manager script
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-K29MXLLX');
    `;
    document.head.appendChild(gtmScript);

    // Google Analytics script
    const gaScript = document.createElement('script');
    gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-SBSN3D4J3P';
    gaScript.async = true;
    document.head.appendChild(gaScript);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-SBSN3D4J3P');
  }, []);

  return (
    <Provider store={configureStore()}>
      <Suspense fallback={<div className="loading" />}>
        <div className="h-100" translate="no">
          <App />
        </div>
      </Suspense>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Google Tag Manager (noscript)
const gtmNoscript = document.createElement('noscript');
gtmNoscript.innerHTML = `
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K29MXLLX"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;
document.body.appendChild(gtmNoscript);
// End Google Tag Manager (noscript)